<template>
  <div class="order-detail-box" v-loading="pageLoading">
    <div class="back-btn">
      <img
        src="../../assets/imgs/back-btn.png"
        @click="$router.back()"
        alt=""
      />
      <span class="user-tit">订单详情</span>
    </div>
    <div class="order-tips">{{ h5OrderInfo.paid | handleOrderTitle }}</div>
    <div class="h5-goods-info">
      <div class="order-info-tit">商品信息</div>
      <div style="position: relative; width: 100%">
        <div class="order-goods-img">
          <!-- .split(',')[0] -->
          <img
            :src="h5OrderInfo.goodsImg || defaultImg"
            alt=""
            class="order-goods-imgval"
            @click="goGoodDetail()"
          />
        </div>
        <div v-if="h5OrderInfo.isUse === 0" class="out-of-stock-layer">
          <svg
            t="1700153530686"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4296"
            width="128"
            height="128"
          >
            <path
              d="M512 949.248c-59.024384 0-116.287488-11.561984-170.200064-34.36544-52.068352-22.023168-98.828288-53.548032-138.981376-93.70112s-71.678976-86.913024-93.702144-138.981376C86.31296 628.287488 74.750976 571.02336 74.750976 512s11.561984-116.287488 34.36544-170.200064c22.023168-52.068352 53.549056-98.828288 93.702144-138.981376s86.913024-71.678976 138.981376-93.70112C395.712512 86.313984 452.975616 74.752 512 74.752s116.287488 11.561984 170.200064 34.36544c52.068352 22.023168 98.828288 53.548032 138.981376 93.70112s71.678976 86.913024 93.702144 138.981376c22.803456 53.912576 34.36544 111.176704 34.36544 170.200064s-11.561984 116.287488-34.36544 170.200064c-22.023168 52.068352-53.549056 98.828288-93.702144 138.981376s-86.913024 71.678976-138.981376 93.70112C628.287488 937.686016 571.024384 949.248 512 949.248zM512 95.232c-56.267776 0-110.846976 11.01824-162.22208 32.74752-49.627136 20.990976-94.198784 51.042304-132.477952 89.321472-38.278144 38.278144-68.330496 82.850816-89.320448 132.477952C106.249216 401.154048 95.232 455.733248 95.232 512.001024s11.01824 110.846976 32.74752 162.22208c20.989952 49.627136 51.042304 94.198784 89.320448 132.477952 38.279168 38.278144 82.850816 68.330496 132.477952 89.321472 51.375104 21.72928 105.954304 32.74752 162.22208 32.74752s110.846976-11.01824 162.22208-32.74752c49.627136-20.990976 94.198784-51.042304 132.477952-89.321472 38.278144-38.278144 68.330496-82.850816 89.320448-132.477952C917.750784 622.848 928.768 568.2688 928.768 512.001024s-11.01824-110.846976-32.74752-162.22208c-20.989952-49.627136-51.042304-94.198784-89.320448-132.477952-38.279168-38.278144-82.850816-68.330496-132.477952-89.321472C622.846976 106.249216 568.267776 95.232 512 95.232z"
              fill="#dbdbdb"
              p-id="4297"
            ></path>
            <path
              d="M190.129152 508.198912l0 77.984768c0 10.789888 5.395456 16.185344 16.431104 16.185344l127.276032 0c6.131712 0 11.035648-1.471488 14.46912-4.41344 5.149696-5.39648 8.338432-21.82656 9.80992-49.782784l12.996608 4.169728c-2.20672 29.673472-6.130688 48.065536-11.77088 54.93248-5.149696 5.149696-12.507136 7.847936-21.825536 7.847936L202.636288 615.122944c-17.412096 0-25.995264-8.828928-25.995264-26.48576L176.641024 458.416128l13.488128 0 0 36.785152 139.293696 0 0-68.665344L162.417664 426.535936l0-12.752896 180.492288 0 0 94.415872L190.129152 508.198912z"
              p-id="4298"
              fill="#dbdbdb"
            ></path>
            <path
              d="M615.611392 413.784064l0 12.997632L507.462656 426.781696l0 40.953856c30.900224 15.939584 62.28992 34.332672 94.170112 55.177216l-7.847936 11.77088c-34.087936-23.051264-62.780416-40.70912-86.322176-53.215232l0 142.235648-12.996608 0L494.466048 426.781696l-95.39584 0 0-12.997632L615.611392 413.784064z"
              p-id="4299"
              fill="#dbdbdb"
            ></path>
            <path
              d="M700.708864 400.295936l0 18.393088c0 1.471488-0.244736 3.187712-0.244736 4.6592l47.57504 0c-0.490496 40.953856-2.942976 65.477632-7.602176 73.570304-4.6592 7.847936-12.752896 12.01664-24.523776 12.01664-3.923968 0-8.338432-0.24576-13.488128-0.735232l-3.433472-12.261376c5.640192 0.490496 10.300416 0.979968 13.978624 0.979968 9.3184-0.244736 15.204352-3.678208 17.656832-10.300416 2.451456-6.62016 3.678208-23.786496 4.168704-51.499008l-35.313664 0c-3.923968 34.824192-19.37408 60.818432-46.103552 77.984768l-9.80992-8.828928c25.014272-15.205376 39.237632-38.25664 42.91584-69.15584l-38.01088 0 0-11.77088 38.992896 0c0-1.717248 0.244736-3.187712 0.244736-4.6592l0-18.393088L700.708864 400.295936zM746.812416 532.967424l0-21.579776 13.243392 0 0 21.579776 99.074048 0 0 12.507136-85.096448 0c17.166336 21.5808 46.349312 40.953856 87.548928 57.63072l-9.073664 11.281408c-42.671104-20.354048-72.834048-43.406336-90.001408-68.912128l-2.451456 0 0 77.249536-13.243392 0L746.812416 545.47456l-2.451456 0c-18.393088 28.447744-49.046528 51.744768-92.208128 69.892096l-7.35744-12.261376c40.70912-14.959616 70.137856-34.087936 88.039424-57.63072l-84.850688 0 0-12.507136L746.812416 532.967424zM848.094208 419.424256l0 80.681984-75.777024 0 0-80.681984L848.094208 419.424256zM835.587072 431.685632l-50.518016 0 0 56.159232 50.518016 0L835.587072 431.685632z"
              p-id="4300"
              fill="#dbdbdb"
            ></path>
          </svg>
          <!-- <div class="message">
                    <p class=""></p>
                    <svg>
                    </svg>
                  </div> -->
        </div>
      </div>
      <div
        class="order-info"
        style="margin-top: 10px"
        @click="goGoodDetail(h5OrderInfo.goodsId)"
      >
        <div>
          {{ h5OrderInfo.goodsName }}
          {{ h5OrderInfo.timeLimitVal ? '/' : '' }}
          {{ h5OrderInfo.timeLimitVal }}
          {{
            h5OrderInfo.expansionSizeVal ? h5OrderInfo.expansionSizeVal : ''
          }}/ ¥{{ h5OrderInfo.originalPrice
          }}{{ h5OrderInfo.productType === 3 ? `/年` : '' }}
        </div>
      </div>
      <div class="order-good-box">
        <div class="order-info">
          <div class="order-info-tit">订单信息</div>
          <div class="order-txt">
            <div class="order-item-tit">订单号：</div>
            <div class="order-item-val">{{ h5OrderInfo.orderId }}</div>
          </div>
          <div class="order-txt">
            <div class="order-item-tit">订单时间：</div>
            <div class="order-item-val">{{ h5OrderInfo.startTime }}</div>
          </div>
          <div
            class="order-txt"
            v-if="![0, 2, 3, 4].includes(h5OrderInfo.paid)"
          >
            <div class="order-item-tit">起止时间：</div>
            <div class="order-item-val">
              {{
                h5OrderInfo.startTime
                  ? h5OrderInfo.startTime.split(' ')[0]
                  : ''
              }}<span style="margin: 0 3px">至</span
              >{{
                h5OrderInfo.expirationTime
                  ? h5OrderInfo.expirationTime.split(' ')[0]
                  : ''
              }}
            </div>
          </div>
          <!-- <div class="order-txt" v-if="h5OrderInfo.type==1">
            <div class="order-item-tit">开始时间：</div>
            <div class="order-item-val">{{ h5OrderInfo.payTime}}</div>
          </div> -->
          <div class="order-txt" v-if="h5OrderInfo.type == 1">
            <div class="order-item-tit">到期时间：</div>
            <div class="order-item-val">{{ h5OrderInfo.expirationTime }}</div>
          </div>
          <div class="order-txt" v-if="h5OrderInfo.type == 1">
            <div class="order-item-tit">有效期：</div>
            <div class="order-item-val">
              {{ h5OrderInfo.expirationTime | handleCulateTime }}
            </div>
          </div>
        </div>
        <div
          class="order-info"
          v-if="h5OrderInfo.payType !== null && h5OrderInfo.paid == 1"
        >
          <div class="order-info-tit">支付信息</div>
          <div class="order-txt">
            <div class="order-item-tit">支付方式:</div>
            <div class="order-item-val">
              {{ h5OrderInfo.payType | handlePayTiype }}
            </div>
          </div>
          <div class="order-txt">
            <div class="order-item-tit">支付金额:</div>
            <div class="order-item-val">
              <span class="m-icon">¥</span>{{ h5OrderInfo.payPrice }}
            </div>
          </div>
          <!-- <div class="order-txt">
            <div class="order-item-tit">购买人:</div>
            <div class="order-item-val">{{ h5OrderInfo.brandName}}</div>
          </div> -->
          <div class="order-txt">
            <div class="order-item-tit">支付时间:</div>
            <div class="order-item-val">{{ h5OrderInfo.payTime }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
export default {
  data() {
    return {
      pageLoading: false,
      defaultImg: require('@/assets/imgs/no-image.png'),
      h5OrderInfo: {},
    }
  },
  filters: {
    handleCulateTime(endDate) {
      if (!endDate) {
        return
      }
      // 计算相隔天数
      const start = dayjs(dayjs().format('YYYY-MM-DD'))
      const end = dayjs(endDate)
      const diffInDays = end.diff(start, 'day')

      // 转换为「n年n月n天」格式
      const years = Math.floor(diffInDays / 365)
      const months = Math.floor((diffInDays % 365) / 30)
      const days = diffInDays % 30
      return `${years}年${months}月${days}天`
    },
    handlePayTiype(type) {
      if (type || type == 0) {
        const payType = {
          0: '微信支付',
          1: '支付宝支付',
        }
        return payType[type]
      }
    },
    handleOrderTitle(value) {
      // 订单已完成支付，感谢您在捷盈网站平台入驻下单！！！
      const statusTitle = {
        0: '订单未支付,请继续支付订单',
        1: '订单已完成支付，感谢您在捷盈企业网站-入驻商城下单！！！',
        3: '订单已关闭',
        4: '订单已取消,请重新选择商品',
        5: '订单退款中',
        6: '订单已退款',
        7: '订单退款失败',
      }
      return statusTitle[value]
    },
  },
  watch: {},
  methods: {
    // 跳转商品详情
    goGoodDetail() {
      const { isUse, policyId, seckillId, productType } = this.h5OrderInfo
      if (isUse === 0 || productType === 2 || productType === 3) {
        const msg =
          isUse === 0
            ? '商品已下架，无法查看商品详情！'
            : productType === 2
            ? '当前订单为内存扩容订单，无法查看商品详情！'
            : '当前订单为升级订单，无法查看商品详情！'
        this.$message.warning(msg)
        return
      }
      switch (productType) {
        case 0:
          this.goGoodDetailPush(productType, policyId)
          break
        case 1:
          this.goGoodDetailPush(productType, seckillId)
          break
        case 2:
          this.goGoodDetailPush(productType)
          break
        default:
          break
      }
    },
    goGoodDetailPush(productType, productID) {
      if (productType == 0) {
        this.$router.push({
          path: '/goods/details',
          query: { productID },
        })
      } else if (productType == 1) {
        this.$router.push({
          path: '/goods/seckilldetails',
          query: { productID },
        })
      } else {
        this.$router.push({
          path: '/goods/internalStorageDetails',
          query: {},
        })
      }
    },
    handleBack() {
      this.$emit('updateIsView', false)
    },
  },
  mounted() {
    this.h5OrderInfo = JSON.parse(localStorage.getItem('orderInfo'))
  },
}
</script>
<style lang="scss" scoped>
// max-width: 420px
@media screen and (min-width: 320px) and (max-width: 768px) {
  // 遮罩层
  .out-of-stock-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    // background-color: red;
    // display: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .message {
    // background-color: #fff;
    color: #fff;
    // padding: 20px;

    line-height: 150px;
    border-radius: 5px;
    text-align: center;
  }

  // .close-btn {
  //   margin-top: 10px;
  //   background-color: #333;
  //   color: #fff;
  //   border: none;
  //   border-radius: 3px;
  //   padding: 5px 10px;
  //   cursor: pointer;
  // }
  // 遮罩层
  .goInfoBtn {
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
  }
  .order-detail-box {
    background-color: #fff;
    width: calc(93% - 40px);
    padding: 0 15px;
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 30px;
  }
  .back-btn {
    display: flex;
    align-items: center;
    color: #fa8919;
    font-size: 20px;
    padding: 20px 0;
    img {
      width: 30px;
      margin-right: 5px;
    }
  }
  .order-tips {
    margin-bottom: 15px;
  }
  .order-goods-img {
    width: 100%;
    margin-top: 10px;
  }
  .order-goods-imgval {
    width: 100%;
    border-radius: 12px;
    object-fit: contain;
  }
  .order-info {
    margin-top: 30px;
    .order-txt {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }
    .order-info-tit {
      margin-bottom: 20px;
    }
    .order-item-tit {
      text-align: right;
      width: 30%;
      color: #888;
    }
    .m-icon {
      font-size: 14px;
      margin-right: 5px;
    }
    .order-item-val {
    }
  }
}
</style>
